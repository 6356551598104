
/* eslint-disable react/no-array-index-key */

import React from 'react';

import { Link } from 'gatsby';

import { improveText } from '../../utils/utils';

const BlogCategoriesWithCounts = ({ site, pageData }) => (
	// console.log('BlogCategoriesWithCounts; pageData ', pageData, ' site ', site);
	<div className='categories--main-categories well'>
		<h2>Categories</h2>
		<ul>
			{Object.entries(pageData.categories).map((entry, index) => {
				const key = entry[0];
				const value = entry[1];

				const text = `${improveText(key, ' ')} (${value.count})`;
				const href = `/categories/#${improveText(key, '_')}`;
				return (
					<li key={index}>
						<Link to={href}>{text}</Link>
					</li>
				);
			})}
		</ul>
	</div>
);

export default BlogCategoriesWithCounts;
