
/* eslint-disable react/no-danger */

import React from 'react';

import { Link } from 'gatsby';

import { FaRegClock, FaChevronRight } from 'react-icons/fa';

const BlogList = ({ site, pageData }) => {
	// console.log('BlogList; pageData ', pageData, ' site ', site);
	const { author } = site.siteMetadata.config;
	return (
		<>
			{pageData.posts.map((post, index) => {
				const { id, fields, frontmatter, excerpt } = post.node;
				// console.log('post ', post);
				return (
					<div key={id}>
						<h2>
							<Link to={frontmatter.permalink}>{frontmatter.title}</Link>
						</h2>
						<p className='lead'>
							{'by '}
							{author}
						</p>
						<p>
							<FaRegClock className='blog-icon' />
							<span>
								{' Posted on '}
								<time>{fields.postDate}</time>
							</span>
						</p>

						<div dangerouslySetInnerHTML={{ __html: excerpt }} />

						<div className='blog-button'>
							<Link className='button-primary' to={frontmatter.permalink}>
								Read More
								<FaChevronRight className='chevron-icon' />
							</Link>
						</div>

						<hr />
					</div>
				);
			})}
		</>
	);
};

export default BlogList;
