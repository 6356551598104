
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { MainLayout, SubpageHeader, createMetaData } from '../components';

import { BlogCategoriesWithCounts, BlogCategoriesWithBlogs } from '../components/blog';

const CategoriesTemplate = (props) => {
	const { pageCategoriesJson: json } = useStaticQuery(graphql`
		query {
			pageCategoriesJson {
				meta_title
				meta_description
				meta_keywords
				title
				permalink
			}
		}
	`);

	// console.log('CategoriesTemplate; props ', props, ' json ', json);

	const { site, page, seo } = createMetaData(json);

	const { pageData } = props.pageContext;

	seo.canonicalUrl = `${site.siteMetadata.canonicalUrl}${pageData.path}`;

	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
			<div>
				<section className='categories'>
					<div className='categories--main'>
						<BlogCategoriesWithCounts pageData={pageData} />
						<BlogCategoriesWithBlogs pageData={pageData} />
					</div>
				</section>
			</div>
		</MainLayout>
	);
};

export default CategoriesTemplate;
