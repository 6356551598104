
export function capitalize(s) {
	if (typeof s !== 'string') return '';
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export function improveText(s, replacement) {
	if (typeof s !== 'string') return '';
	const arr = [];
	s.split(' ').forEach((item) => {
		arr.push(capitalize(item));
	});
	return arr.join(replacement);
}

export function joinArray(arr, addStop = true) {
	const str = [arr.slice(0, -1).join(', '), arr.slice(-1)[0]]
		.join(arr.length < 2 ? '' : ' and ')
		+ (addStop && arr.length > 0 ? '.' : '');
	return str;
}
