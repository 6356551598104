
/* eslint-disable react/no-array-index-key */

import React from 'react';

import { Link } from 'gatsby';

import { improveText } from '../../utils/utils';

const BlogCategoriesWithBlogs = ({ pageData }) => (
	// console.log('BlogCategoriesWithBlogs; pageData ', pageData);
	<>
		{pageData.slicedCategories.map((categories, idx1) => (
			<div key={idx1} className='categories--main-list well'>
				{Object.entries(categories).map((entry, index) => {
					const key = entry[0];
					const value = entry[1];

					const text = `${improveText(key, ' ')}`;
					const href = `${improveText(key, '_')}`;

					return (
						<div key={index}>
							<h3 id={href}>{text}</h3>
							<ul>
								{value.list.map((item, idx2) => (
									<li key={idx2}>
										<Link to={item.key}>{item.value}</Link>
									</li>
								))}
							</ul>
						</div>
					);
				})}
			</div>
		))}
	</>
);

export default BlogCategoriesWithBlogs;
